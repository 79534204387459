.main-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('/public/images/main/10.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    overflow: hidden;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Oscurece la imagen de fondo */
  }
  
  .content {
    position: relative;
    z-index: 2;
    text-align: center;
    margin-top: 60vh; /* Empuja el contenido hacia abajo un 15% de la altura de la pantalla */
  }
  
  .content h1 {
    font-size: 3rem; /* Tamaño de fuente relativo a la raíz */
    font-weight: 700;
    margin-bottom: 1vh; /* Espacio inferior relativo a la altura de la pantalla */
  }
  
  .content p {
    font-size: 1.2rem;
    margin-bottom: 10vh; /* Espacio inferior para separarlo del botón */
  }
  
  .access-button {
    padding: 2rem 5rem; /* Tamaño del botón en rem para que sea adaptable */
    font-size: 1.5rem;
    color: white;
    background-color: transparent;
    border: 0.2rem solid white; /* Borde más adaptable */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .access-button:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Efecto hover con fondo blanco translúcido */
  }
  
  .home-icon {
    position: absolute;
    bottom: 2vh;
    right: 2vw;
    width: 5vw; /* Tamaño del ícono relativo al ancho de la pantalla */
    height: auto; /* Mantiene la proporción del ícono */
    cursor: pointer;
    z-index: 2;
  }
  .main-section .content p {
    font-family: 'Sorts Mill Goudy', serif;
  }
  
  .main-section .access-button {
    font-family: 'Alice', serif;
  }
  