@import '../../../variables.css';
/* ControlOperario.css */
.controlOperarioContainer {
    background-color: var(--color-background);
  }
  
  .paper {
    margin: 16px;
    padding: 16px;
  }
  
  .searchSection {
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .detailsSection {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .printSection {
    display: flex;
    justify-content: flex-end;
  }
  /* ControlOperario.css */
.controlOperarioContainer {
  background-color: var(--color-background);
  width: 100%; /* Ajustar para que ocupe todo el ancho */
}

.paper {
  margin: 16px;
  padding: 16px;
  width: calc(100% - 32px); /* Ajustar para que ocupe todo el ancho menos el margen */
}



/* Solución al problema de texto blanco en inputs */
.MuiInputBase-input {
  color: black !important;
}
/* Estilo para el label del checkbox 'Asistió' */
.controlOperarioContainer .MuiFormControlLabel-label {
  color: rgba(0, 0, 0, 0.87); /* Cambia a negro con la opacidad por defecto de Material UI */
}
/* Ajustes adicionales de estilo pueden ser necesarios una vez que veas el resultado en tu aplicación */
