@import '../../../variables.css';

/* Estilos base para el contenedor y los TextField */
.agregarOperarioContainer {
  background-color: var(--color-background-admin); /* Fondo definido en variables.css */
  margin: 0;
  padding: 20px;
  width: 100%;
}

.MuiTextField-root {
  margin-bottom: 1rem; /* Espacio después de cada TextField */
}

/* Estilos base para texto y borde de inputs y selects */
.agregarOperarioContainer .MuiInputBase-input,
.agregarOperarioContainer .MuiSelect-select,
.agregarOperarioContainer .MuiInputLabel-root,
.agregarOperarioContainer .MuiFormControlLabel-label,
.agregarOperarioContainer .MuiMenuItem-root {
  color: black; /* Texto negro en todos estos elementos */
}

/* Estilo específico para el borde de los TextField */
.agregarOperarioContainer .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: black;
}


/* Estilo para ocultar el asterisco en campos requeridos */
.agregarOperarioContainer .MuiInputLabel-asterisk.Mui-required {
  display: none; /* Oculta el asterisco */
}

/* Estilo para los labels cuando están 'shrink' (reducidos por el efecto de 'float') */
.agregarOperarioContainer .MuiInputLabel-shrink {
  color: black;
  background: var(--color-background-admin);
  padding: 0 4px;
  border-radius: 4px;
}
.agregarOperarioContainer .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent; /* Esto hace que el indicador de foco sea transparente, eliminándolo */
}