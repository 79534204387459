/* Venta.css */

/* Estilos generales del contenedor */
.controlOperarioContainer {
  background-color: white; /* Fondo blanco para todo el componente */
}

/* Estilos para los campos de búsqueda */
/* Se elimina la referencia a '& input' ya que no es necesaria en CSS puro */
.input {
  color: black; /* Texto de los campos de búsqueda en negro */
}

/* Estilos para las tarjetas de productos y servicios */
.MuiCard-root {
  margin-bottom: 16px; /* Espacio entre tarjetas */
}

/* Estilos para los botones grandes y su contenedor */
.MuiButton-sizeLarge {
  width: 100%; /* Los botones grandes ocupan todo el ancho disponible */
}
.MuiGrid-container {
  margin: 0; /* Elimina los márgenes por defecto del contenedor de la cuadrícula */
  width: 100%; /* Asegura que el contenedor de la cuadrícula use todo el ancho disponible */
}

/* Estilos para el contenido de los medios en las tarjetas */
.MuiCardMedia-root {
  /* Elimina el paddingTop y usa altura automática para las imágenes */
  height: auto;
  background-size: cover; /* Asegura que la imagen cubra todo el espacio sin distorsionarse */
  background-position: center; /* Centra la imagen dentro del elemento */
}

/* Estilos para el contenido de las tarjetas */
.MuiCardContent-root {
  padding: 8px; /* Reduce el relleno dentro de las tarjetas */
}

/* Estilo para el último hijo de MuiCardContent-root para mantener la uniformidad */
.MuiCardContent-root:last-child {
  padding-bottom: 8px;
}

/* Ajustes responsivos para dispositivos móviles */
@media (max-width: 600px) {
  .MuiCard-root {
    margin-bottom: 8px; /* Menos espacio entre tarjetas en dispositivos móviles */
  }
  .MuiCardContent-root {
    padding: 4px; /* Menos relleno en dispositivos móviles */
  }
}


/* Venta.css */

/* Estilos para las tarjetas de productos y servicios */
.MuiCard-root {
  margin-bottom: 16px;
  max-width: 300px; /* Ajusta este valor según la anchura deseada */
  margin-left: auto;
  margin-right: auto;
}

/* Estilos para el contenido de los medios en las tarjetas */
.MuiCardMedia-root {
  object-fit: contain;
  height: 140px; /* Ajusta la altura como sea necesario */
}



/* Estilos para alinear los elementos dentro del CardContent */
.MuiGrid-container {
  flex-wrap: nowrap;
}

/* Estilos para el texto dentro de la tarjeta */
.MuiTypography-h6 {
  font-size: 1rem; /* Ajusta el tamaño del título según tus preferencias */
}
.MuiTypography-body1 {
  font-size: 0.875rem; /* Ajusta el tamaño del cuerpo del texto según tus preferencias */
}
.MuiTypography-body2 {
  font-size: 0.875rem;
}

/* Ajustes para que el diseño sea responsivo */
@media (max-width: 600px) {
  .MuiCard-root {
    max-width: 100%; /* Las tarjetas se expanden para ocupar el espacio disponible */
  }
}
/* Asegúrate de que la propiedad de objectFit está aplicada correctamente */
.MuiCardMedia-root {
  object-fit: contain;
  height: 140px; /* Ajusta la altura como sea necesario */
}

.custom-textfield input {
  border: 2px solid blue; /* Cambia el color del borde a azul */
  outline: none; /* Elimina el contorno cuando está enfocado */
  transition: border-color 0.2s ease; /* Agrega una transición suave al cambio de color del borde */
}

.custom-textfield input:focus {
  border-color: navy; /* Cambia el color del borde a navy cuando el campo está enfocado */
}

.icon-container {
  display: flex;
  justify-content: center; /* Centrar íconos horizontalmente */
  align-items: center; /* Centrar íconos verticalmente */
  margin-bottom: 10px; /* Agrega un espacio debajo de los íconos */
}
