/* Reset some default styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  position: fixed; /* Hace que el header esté fijo en la parte superior */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; /* Asegura que esté encima del contenido */
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente opcional */
  padding: 20px;
}

.logo-container {
  display: flex;
  align-items: center;
  padding-left: 20px; /* Espacio desde el borde izquierdo */
}

.logo-link {
  display: inline-block;
  text-decoration: none;
}

.logo-image {
  height: 40px; /* Ajusta el tamaño del logo si es necesario */
  opacity: 0.9; /* Opcional, ajusta la opacidad para un efecto sutil */
}

/* Línea blanca en la parte inferior */
.bottom-line {
  width: 100%;
  height: 2px;
  background-color: white;
  margin-top: 10px; /* Ajusta el espacio entre el logo y la línea */
  opacity: 0.7; /* Opcional, ajusta la opacidad si lo deseas */
}
