/* Atencion.css */
.atencion-container {
    width: 100%;
  }
  
  .atencion-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .atencion-section {
    display: flex;
    justify-content: center;
  }
  