/* Contenedor principal centrado vertical y horizontalmente */
.login-form-container {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    padding: 5% 10%;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Centra el formulario verticalmente */
    box-sizing: border-box;
  }
  @media (max-width: 480px) {
    .login-form-container {
      width: 95%; /* Ocupa casi todo el ancho disponible */
      max-width: 500px; /* Reduce el ancho máximo */
      padding: 10% 5%; /* Reduce el padding interno */
    }
  }
  @media (max-width: 480px) {
    .login-form-container {
      padding: 5% 5%; /* Menos padding lateral */
    }
  }
  
  /* Logo grande y centrado */
  .logo {
    width: 80%; /* Tamaño del logo en porcentaje del contenedor */
    max-width: 200px; /* Limita el ancho máximo en pantallas grandes */
    margin-bottom: 4vh;
  }
  
  /* Texto "BIENVENIDO" */
  .bienvenido-text {
    font-size: 2vw; /* Ajuste del tamaño del texto en base al ancho de la pantalla */
    font-weight: bold;
    color: white;
    margin-top: 1vh;
  }
  
  /* Línea de separación centrada y extendida */
  .divider {
    width: 80vw; /* Línea más larga en base al ancho de la ventana */
    max-width: 600px; /* Define un límite para el ancho máximo de la línea */
    border: 1px solid white;
    margin: 1vh auto 5vh; /* Ajusta el margen para centrar verticalmente */
  }
  
  /* Margen superior solo para el primer campo de entrada */
  .user-box:first-of-type {
    margin-top: 10vh; /* Espacio adicional solo en el primer campo */
  }
  
  .user-box {
    position: relative;
    margin-bottom: 2vh; /* Espacio entre los campos de entrada */
    width: 100%;
  }
  
  /* Estilo de los campos de entrada */
  input {
    width: 100%;
    padding: 1.5% 2%;
    font-size: 3.1vw; /* Ajusta el tamaño del texto */
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: white;
  }
  
  input::placeholder {
    color: #ddd;
  }
  
  .password-toggle {
    opacity: 0;
    transform: translateY(-50%) scale(0.9); /* Aparece más pequeño */
    animation: fadeIn 0.3s ease forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }
  }
  
  @media (max-width: 480px) {
    input {
      width: 90%; /* Reduce ligeramente el ancho en pantallas pequeñas */
      font-size: 6.5vw;
    }
  }
  
  /* Estilo del botón de ingreso */
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
  }
  
  .ingreso-button {
    padding: 1.5% 5%;
    font-size: 1.2vw;
    background-color: #6c5b3d;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .ingreso-button:hover {
    background-color: #5a472f;
  }
  