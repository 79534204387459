.corner-buttons {
    display: flex;
    justify-content: space-between; /* Coloca los botones en las esquinas */
    position: fixed; /* Fija los botones en la pantalla */
    bottom: 2%; /* Posición desde la parte inferior */
    left: 0; /* Empieza desde el borde izquierdo */
    right: 0; /* Llega hasta el borde derecho */
    width: 100%; /* Asegura que el contenedor ocupe toda la pantalla */
    padding: 0 2%; /* Espaciado lateral para evitar que queden pegados a los bordes */
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
  }
  
  .corner-button {
    background: none; /* Sin fondo */
    border: none; /* Sin bordes */
    display: flex; /* Centra el ícono dentro del botón */
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Cambia el cursor a "mano" */
    width: auto; /* Se ajusta al tamaño del ícono */
    height: auto; /* Se ajusta al tamaño del ícono */
  }
  
  .corner-icon {
    width: 40px; /* Tamaño del ícono (ajústalo según prefieras) */
    height: auto; /* Mantiene la proporción del ícono */
  }
  