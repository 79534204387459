/* AgregarProducto.css */
@import '../../../variables.css';

.agregar-producto-container {
  padding: 20px;
  gap: 20px;
  background-color: var(--color-background-admin);
}

/* Estilo para los labels de los Radio buttons */
.radio-button-label {
  color: #000; /* Negro */
}

/* Estilo para los inputs de texto y Autocomplete */
.text-field-input {
  color: #000; /* Negro */
  background-color: #fff; /* Fondo blanco */
}

/* Estilo para el texto dentro del Autocomplete */
.autocomplete-input-root .MuiAutocomplete-input {
  color: #000; /* Negro */
}
.buscar-input input {
  color: #000; /* Texto negro */
}



/* Estilo para ocultar el asterisco en campos requeridos */
.agregarOperarioContainer .MuiInputLabel-asterisk.Mui-required {
  display: none; /* Oculta el asterisco */
}

/* Estilo para los labels cuando están 'shrink' (reducidos por el efecto de 'float') */
.agregarOperarioContainer .MuiInputLabel-shrink {
  color: black;
  background: var(--color-background-admin);
  padding: 0 4px;
  border-radius: 4px;
}
.agregarOperarioContainer .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent; /* Esto hace que el indicador de foco sea transparente, eliminándolo */
}

