/* LoginPage.css */
.login-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: url('/public/images/main/15.png') no-repeat center center;
  background-size: cover;
  font-family: 'Arial', sans-serif;
}

.login-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
}

.left-column,
.right-column {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ocultar las imágenes (columna izquierda) en pantallas pequeñas */
@media (max-width: 768px) {
  .left-column {
    display: none; /* Oculta la columna izquierda */
  }

  .right-column {
    width: 100%; /* El formulario ocupa todo el ancho */
  }
}

.footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.home-button {
  background: none;
  border: none;
  cursor: pointer;
}

.home-button img {
  width: 40px;
  height: 40px;
}
