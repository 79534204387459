.main-layout-container {
  width: 100%; /* Asegura que ocupa el 100% del ancho */
  min-height: 100vh; /* Usa min-height en lugar de height para permitir flexibilidad en la altura */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('/public/images/main/14.png'); /* Ruta de la imagen de fondo */
  background-size: cover; /* Cambia a cover para asegurar que ocupa todo el contenedor */
  background-position: center top; /* Centra la imagen vertical y horizontalmente */
  background-repeat: no-repeat; /* Evita que se repita */
  overflow: hidden; /* Evita desplazamientos tanto laterales como verticales */
}



.top-row {
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.middle-row {
  display: flex;
  flex-wrap: wrap; /* Permite que los botones se acomoden en varias filas si es necesario */
  justify-content: center;
  align-items: center;
  gap: 5%; /* Espaciado flexible entre botones */
  width: 100%;
  margin-top: 30%; /* Mueve los íconos hacia abajo un 40% */
  margin-bottom: 5%;
  flex-grow: 1;
  padding: 0 5%; /* Evita que los íconos queden demasiado pegados a los bordes */
}


.bottom-row {
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5%;
  margin-top: 5%;
}

.header-text {
  color: white;
  font-size: 1.8rem; /* Tamaño de texto adaptable */
}

.header-subtext {
  color: white;
  font-size: 1rem; /* Tamaño de texto adaptable */
}

.header-line {
  width: 80%; /* Ajuste dinámico para diferentes pantallas */
  max-width: 90%; /* Limita el ancho máximo */
  height: 0.2%; /* Altura proporcional */
  background-color: white;
  margin: 1% 0;
}

.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-decoration: none;
  min-width: 20%; /* Ajuste flexible basado en porcentaje */
  width: 20%; /* Ajuste flexible basado en porcentaje */
  max-width: 25%;

}

.icon-image {
  width: 25vw; /* El ancho será el 10% del ancho de la ventana */
  max-width: 350px; /* Limita el tamaño máximo a 80px */
  min-width: 150px; /* Limita el tamaño mínimo a 50px */
  height: auto; /* Mantén la proporción de la imagen */
  border-radius: 50%;
  margin-bottom: 5%;
  transition: transform 0.3s ease;
}

.icon-image:hover {
  transform: scale(1.2); /* Aumenta el tamaño al pasar el cursor */
}



.icon-text {
  color: white;
  font-size: 1rem; /* Tamaño de texto adaptable */
  text-align: center;
}

.bottom-icon-button {
  color: white;
  font-size: 2.5rem; /* Cambia el valor según el tamaño que desees */
}

.bottom-icon {
  width: 8%; /* Tamaño relativo */
  height: 8%; /* Tamaño relativo */
}
