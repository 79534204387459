/* variables.css */
:root {
    /* Colores */
    --color-primary: #021f45;
    --color-secondary: #0fbdd0;
    --color-text: #ffffff;
  
    /* Fuentes */
    --font-primary: 'Archivo Black', sans-serif;
    --font-secondary: 'Archivo Narrow', sans-serif;
    --font-alternative: 'Chinzel', sans-serif;
    --font-content: 'Crimson Pro', serif;
    
    /* Tamaños de fuente */
    --font-size-title: 2em; /* Ajustar según sea necesario */
    --font-size-subtitle: 1.5em;
    --font-size-content: 1em;
    
    /* Estilos de fuente */
    --font-weight-normal: normal;
    --font-weight-bold: bold;

    --color-background-admin: #fff; /* Color de fondo blanco, o cualquier otro color que prefieras */
  }
  