/* SueldosOperario.css */
@import '../../../variables.css';

.sueldos-operario-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-background-admin);
}

.left-panel, .right-panel {
  width: 50%;
  padding: 1em;
}

.detail-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.detail-search input {
  flex-grow: 1;
  margin-right: 0.5em;
}

.action-buttons button {
  margin-right: 0.5em;
}

/* Add more styles for tables, buttons, and layout adjustments */
