/* OpcionCard.css */

.opcion-card-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;               /* Tamaño máximo de la tarjeta */
    padding: 0;
    box-shadow: none;
    border-radius: 8px;             /* Bordes redondeados para un aspecto más amigable */
    margin: 0;
    text-transform: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transición suave al hacer hover */
  }
  
  .opcion-card-button:hover {
    transform: scale(1.05);          /* Efecto de aumento en hover */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); /* Sombra en hover */
  }
  
  .opcion-card-image {
    width: 100%;
    height: 80%;                    /* Aumenta la altura para que sea más rectangular */
    max-height: 300px;              /* Limite máximo para la altura de la imagen */
    object-fit: cover;
    margin: 0;
    display: block;
    border-radius: 8px 8px 0 0;     /* Bordes redondeados solo en la parte superior */
    transition: transform 0.3s ease; /* Transición suave para la imagen */
  }
  
  .opcion-card-text {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    padding: 0;
    margin-top: 2%;
    margin-bottom: 3%;
    line-height: 1.2;
    width: 100%;
    font-family: 'Goudy Old Style', serif;
    border-bottom: 1px solid #000;
  }
  