.bottom-row {
  display: flex;
  justify-content: space-between; /* Coloca los botones en los extremos */
  position: absolute; /* Fija la posición de los botones */
  bottom: 10%; /* Ajusta la altura desde la parte inferior */
  width: 100%;
  padding: 0; /* Sin padding adicional */
}

.bottom-icon-button {
  background: none; /* Fondo transparente */
  border: none; /* Sin bordes */
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  cursor: pointer;
  width: 35px; /* Ajusta el tamaño del botón al ícono */
  height: 35px; /* Tamaño cuadrado */
}

.bottom-icon {
  width: 100%; /* Asegura que el ícono ocupe todo el botón */
  height: auto; /* Mantiene la proporción original del ícono */
}

/* Añadir espacio entre los botones */
.bottom-row > :first-child {
  margin-left: 5%; /* Espaciado desde el borde izquierdo */
}

.bottom-row > :last-child {
  margin-right: 5%; /* Espaciado desde el borde derecho */
}

.bottom-row::before {
  content: ""; /* Elemento vacío para ocupar espacio */
  flex: 1; /* Ocupa el espacio intermedio */
  margin: 0 20%; /* Incrementa el espacio intermedio al 70% */
}
