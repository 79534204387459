/* ImagePair.css */
.image-pair-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    position: absolute;
    width: 35%; /* Ajusta el tamaño en porcentaje para adaptarse a diferentes tamaños de pantalla */
    height: auto; /* Mantiene la proporción de la imagen */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
  }
  
  .image-top {
    top: 30%; /* Posicionamiento vertical hacia arriba */
    left: 10%; /* Posicionamiento hacia la izquierda */
    z-index: 2;
  }
  
  .image-bottom {
    top: 20%; /* Posicionamiento vertical hacia abajo */
    left: 30%; /* Posicionamiento hacia la derecha */
    z-index: 1;
  }
  